import Vue from 'vue';
import {
  Divider, NavBar, Radio, Dialog,
} from 'vant';
import { mapActions } from 'vuex';
import { Checkbox, CheckboxGroup ,Calendar } from 'vant';
import { Empty } from 'vant';

Vue.use(Empty);

// 全局注册
Vue.use(Calendar);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Dialog);
Vue.use(Divider);
Vue.use(NavBar);
Vue.use(Radio);

export default {
  components: {},
  data() {
    return {
      radio1: [],
      lists: [ ],
      result: ['a', 'b'],
    };
  },
  watch: {
    $route() {
      this.getLists();
    }
    },
  created() {

    this.getLists();
    // this.$router.go(0)
  },
  methods: {
    ...mapActions('address', ['defaultAddress', 'delAddress', 'addAddress', 'editAddress', 'findAddress', 'addressDetail','editDefaultAddress']),
    huoqu(shoppingAddressId){
     if(this.radio1[0]==shoppingAddressId){
       this.radio1[0]=shoppingAddressId
     }else{
      if(this.radio1.length>1){
        this.radio1.shift();
     }else if(this.radio1.length==0){

     }else{
        this.radio1[0]=shoppingAddressId
    }
     }
      var data={
        shoppingAddressId:shoppingAddressId,
      }
      this.editDefaultAddress(data).then(res=>{
        if(res.code==200){
        }
      })
    },
    back() {
      if(this.$route.query.back==0){
       this.$router.push({name:'orderSure',query:{payType:this.$route.query.payType,undo:0}})
      }else if(this.$route.query.back==1){
        this.$router.push({name:'Mine'})
      }else if(this.$route.query.back==3){
        this.$router.push({name:'orderDetail',query:{back:this.$route.query.back}})
      }else{
        // this.$router.go(-1);
        this.$router.push({name:'orderSure'})
      }


    },
    toEdit(shoppingAddressId) {

      this.$router.push({
        name: 'editAddress',
        query: {
          id:shoppingAddressId,
          back:this.$route.query.back,
          payType:this.$route.query.payType
        }
      })
    },
    tonew() {
      this.$router.push({ path: '/newAddress',
      query:{back:this.$route.query.back,payType:this.$route.query.payType}
     });
    },
    toPay(shoppingAddressId){
      if(this.$route.query.ismine){

      }else{
        if(this.$route.query.back==0){
          this.$router.push({
            name: 'orderSure',
            query: {
              id:shoppingAddressId,
              payType:this.$route.query.payType,
              undo:0
            }
          })
        }else if(this.$route.query.back==3){
          this.$router.push({
            name: 'orderDetail',
            query: {
              addressId:shoppingAddressId,
              id:this.$route.query.orderId,
              orderBack:this.$route.query.orderBack
            }
          })
        }

      }



    },
    getLists(){
      this.findAddress().then((res) => {
        this.lists=res.data;
        if (res.code == 200){
          for (let i = 0; i < res.data.length; i++) {
            if(res.data[i].shoppingAddressState==1){

              this.radio1[0]=res.data[i].shoppingAddressId
              return
            }else{
              this.radio1=[]
            }
          }
        }
      });

    },
    deleteByID(id) {
      Dialog.confirm({
        message: '确认删除吗',
      })
        .then(() => {
          //this.lists.splice(this.lists.find((item) => item.id === id), 1);
          this.delAddress(id).then((res) => {
            if (res.code== 200){
                this.getLists();
            }else{
              Dialog.alert({
                message: '默认地址不可删除',
              }).then(() => {
                // on close
              });
            }
          });
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
